import { Spinner as ReactSpinner } from 'react-bootstrap';
import '../../css/SpinnerOverlay.css';

export default function SpinnerOverlay({ show = false, activeRequests, text = "" }) {
    return (
        (show || activeRequests) > 0 &&
        <div className="spinner-background">
            <div className="d-flex flex-column align-items-center px-5 spinner-box">
                <ReactSpinner animation="border" role="status" size="md" />

                <span className="pt-3">{text}</span>
            </div>
        </div>
    );
}