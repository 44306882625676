import { Routes, Route } from 'react-router-dom';
import MainApp from './MainApp';
import DirectLink from './DirectLink';

const App = () => {
	return (
	  <Routes>
		<Route path="/" element={<MainApp />} />
		<Route path="/directLink/:token" element={<DirectLink />} />
	  </Routes>
	);
  };
  
  export default App;