// @flow
import React, { Fragment } from 'react';
import { Image } from 'react-bootstrap';
import { getImage } from '../misc/utils';
import { getComplexValue } from '../misc/utils';
import { Trans } from 'react-i18next';
import { PATH_PICTURE } from '../backend/paths';

import TouchHandler from '../components/common/TouchHandler';

import { OBJECTLIST_RENDERER_MODE } from '../misc/const';

import {
	RowRendererType,
	IMGRendererType,
	CheckboxRendererType,
	TransColumnRendererType,
	ObjectListColumnRendererType,
	FormTableIconDirectionRendererType,
} from '../misc/flowTypes';

/**
 * Renderer der ein Bild fuer die Stationsliste erstellt
 *
 * @export
 * @param {StationRendererType} {row} Spalte, die gerendert wird
 * @returns {?React$Element<any>} JSX Image
 */
export function stationImgRenderer({ row }: RowRendererType): ?React$Element<any> {
	let picture: ?React$Element<any> = null;

	// if (row.original.hasOwnProperty('photoUrl') && row.original.photoUrl !== '' && row.original.fileExists === true) {
	// 	console.log('row.original:', row.original);
	// 	picture = <Image fluid src={row.original.photoUrl}></Image>;
	// }
	// else if (row.original.hasOwnProperty('photoUrl') && row.original.photoUrl !== '' && row.original.fileExists === false) {
	// 	return getImage('invalidImage', null);
	// }
	
	if (row.original.hasOwnProperty('photoRef') && row.original.photoRef !== '' && row.original.fileExists === true) {
		const photoUrl = `${PATH_PICTURE}/${row.original.photoRef}`;
		picture = <Image fluid src={photoUrl}></Image>;
	}
	else if (row.original.hasOwnProperty('photoRef') && row.original.photoRef !== '' && row.original.fileExists === false) {
		return getImage('invalidImage', null);
	}

	return picture;
}

/**
 * Rendert ein Bild fuer die Tabelle
 *
 * @export
 * @param {IMGRendererType} {type} Typ des Bildes
 * @returns {React$Element<any>} JSX Image
 */
export function imgRenderer({ type, clazz }: IMGRendererType): ?React$Element<any> {
	if (type) {
		return getImage(type.toLowerCase(), clazz);
	} else {
		return <div></div>;
	}
}

/**
 * Rendert eine Checkbox fuer die Tabelle
 *
 * @export
 * @param {CheckboxRendererType} {value} Wert der Checkbox
 * @returns {React$Element<any>} JSX Checkbox
 */
export function checkBoxRenderer({ value }: CheckboxRendererType): React$Element<any> {
	if (!value) {
		return <span></span>;
	} else {
		return <Trans i18nKey="common.yes">Ja</Trans>;
	}
}

/**
 * Rendert ein Trans Objekt fuer die Uebersetzung
 * @param  {array} args Array mit allen uebergebenen Parametern
 * @return {JSX}        JSX Trans Object
 */
export function transColumnRenderer({ key, fallback, parent = 'div' }: TransColumnRendererType): React$Element<any> {
	return (
		<Trans i18nKey={key} parent={parent}>
			{fallback}
		</Trans>
	);
}


 export function tableArrowGegenUntersuchungRenderer({ row }): React$Element<any> {
	if (parseInt(row.value, 2) !== 1) {
		return (<div />);
	}
	return getImage('arrowUp');
}

/**
 * Rendert eine Zelle fuer Komplexeobjekte
 * @param  {array} args  Array mit allen uebergebenen Parameter
 * @return {JSX}         Die Zelle
 */
export function modeRenderer({ row }: RowRendererType): React$Element<any> {
	let result: React$Element<any> = <div>{row.value}</div>;
	if (row.column.hasOwnProperty('mode') && row.column.mode !== 'default') {
		result = <div>{getComplexValue(row.value, row.column.mode)}</div>;
	}
	return result;
}

/**
 * Rendert eine Zelle fuer eine Form aus Bild und Text
 *
 * @export
 * @param {FormTableIconDirectionRendererType} {type} Typ des Bildes
 * @returns {React$Element<any>}
 */
export function formTableIconDirectionRenderer({ type }: FormTableIconDirectionRendererType): React$Element<any> {
	const img = imgRenderer({ type, clazz: 'columnImg' });

	let transInfo: TransColumnRendererType;
	if (type === 'in') {
		transInfo = {
			key: 'object.inflow',
			fallback: 'Zulauf',
			parent: 'span',
		};
	} else if (type === 'out') {
		transInfo = {
			key: 'object.outflow',
			fallback: 'Ablauf',
			parent: 'span',
		};
	}

	const text = transColumnRenderer(transInfo);

	return (
		<Fragment>
			{img}
			{text}
		</Fragment>
	);
}

/**
 * Rendert eine Zelle fuer die Objektliste
 * @param  {array} args   Array mit allen uebergebenen Parameter
 * @param  {object} props Die aktuellen Redux properties
 * @return {JSX}          Die gerenderte Zelle
 */
export function objectListColumnRenderer({ row, mode = OBJECTLIST_RENDERER_MODE.TEXT }: ObjectListColumnRendererType, props: Object): React$Element<any> {
	let item: ?React$Element<any> = null;

	if (row && mode) {
		switch (mode) {
			case OBJECTLIST_RENDERER_MODE.IMG:
				item = <div>{imgRenderer({ type: row.value })}</div>;
				break;
			case OBJECTLIST_RENDERER_MODE.TEXT:
				item = modeRenderer({ row });
				break;
			case OBJECTLIST_RENDERER_MODE.CHECKBOX:
				item = <div>{checkBoxRenderer({ value: row.value })}</div>;
				break;
			default:
				item = modeRenderer({ row });
				break;
		}
	}

	const result: React$Element<any> = (
		<TouchHandler
			onPress={() => {
				props.objectOpened(row.original);
			}}
		>
			{item}
		</TouchHandler>
	);

	return result;
}
