import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Spinner from './common/SpinnerOverlay';
import { _loginByDirectLink } from '../actions/loginActions';

export default function DirectLink () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { token } = useParams();
    
    useEffect(()=> {     
        dispatch(_loginByDirectLink(token))
            .then(() => navigate("/"));                   
    },[dispatch, navigate, token]);
       
    return (
        <Spinner show text={t('common.redirection')}/>
    );
  };