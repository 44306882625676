import * as Environment from '../misc/helper/environmentHelper';
import { getUrlWithPath } from './API';

// Pfade
export const PATH_DEFAULT: string = Environment.apiRoute; // '/api/public';

export const PATH_AUTH: string = `${PATH_DEFAULT}/auth`;
export const PATH_AUTH_DIRECTLINK: string = `${PATH_DEFAULT}/auth/directLink/:link`;
export const PATH_WHOAMI: string = `${PATH_DEFAULT}/whoami`;
export const PATH_PING: string = `${PATH_DEFAULT}/ping`;
export const PATH_FORGOT_PASSWORD: string = `${PATH_DEFAULT}/forgotpassword`;
export const PATH_RESET_PASSWORD: string = `${PATH_DEFAULT}/resetpassword`;
export const PATH_PROJECT: string = `${PATH_DEFAULT}/project`;
export const PATH_OBJECT: string = `${PATH_DEFAULT}/object`;
export const PATH_INSPECTION: string = `${PATH_DEFAULT}/inspection`;
export const PATH_STATION: string = `${PATH_DEFAULT}/station`;
export const PATH_VIDEO: string = getUrlWithPath(`${PATH_DEFAULT}/video`);
export const PATH_PICTURE: string = getUrlWithPath(`${PATH_DEFAULT}/picture`);
export const PATH_SETTINGS: string = `${PATH_DEFAULT}/settings`;
export const PATH_UNLOCK: string = `${PATH_DEFAULT}/unlock`;
export const PATH_PREVIEW: string = `${PATH_DEFAULT}/preview`;
export const PATH_PANORAMO: string = getUrlWithPath(`${PATH_DEFAULT}/panoramo`);
