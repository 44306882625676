//actions
export const NO_ACTION: string = 'NO_ACTION';
export const INIT: string = 'INIT';
export const SWITCH_PROJECT: string = 'SWITCH_PROJECT';
export const OBJECT_OPENED: string = 'OBJECT_OPENED';
export const OBJECT_SELECTED: string = 'OBJECT_SELECTED';
export const STATION_SELECTED: string = 'STATION_SELECTED';
export const SEEK_STATION: string = 'SEEK_STATION';
export const ADD_STATION_VIDEO_VIEW_INSTANCE: string = 'ADD_STATION_VIDEO_VIEW_INSTANCE';
export const REMOVE_STATION_VIDEO_VIEW_INSTANCE: string = 'REMOVE_STATION_VIDEO_VIEW_INSTANCE';
export const UPDATE_STATION_VIDEO_VIEW_INSTANCE: string = 'UPDATE_STATION_VIDEO_VIEW_INSTANCE';

export const ADD_PANO_VIEW_INSTANCE: string = 'ADD_PANO_VIEW_INSTANCE';
export const UPDATE_PANO_VIEW_INSTANCE: string = 'UPDATE_PANO_VIEW_INSTANCE';
export const REMOVE_PANO_VIEW_INSTANCE: string = 'REMOVE_PANO_VIEW_INSTANCE';

export const BACK_TO_PROJECT: string = 'BACK_TO_PROJECT';
export const SET_MAIN_VIEW_TAB: string = 'SET_MAIN_VIEW_TAB';
export const SHOW_SIDE_VIEW: string = 'SHOW_SIDE_VIEW';
export const LOGIN: string = 'LOGIN';
export const SET_IS_DIRECTLINK: string = 'SET_IS_DIRECTLINK';
export const SWITCH_STATION_VIEW: string = 'SWITCH_STATION_VIEW';
export const NEXT_STATION_IMAGE: string = 'NEXT_STATION_IMAGE';
export const SET_SESSION_PARAMETER: string = 'SET_SESSION_PAREMETER';

export const LOGIN_SUCCESSFUL: string = 'LOGIN_SUCCESSFUL';
export const DISPATCH_MESSAGE: string = 'DISPATCH_MESSAGE';
export const LOGIN_FAILED: string = 'LOGIN_FAILED';
export const LOGIN_RESET: string = 'LOGIN_RESET';
export const USER_DATA_RECEIVED: string = 'USER_DATA_RECEIVED';
export const TOKEN_EXPIRED: string = 'TOKEN_EXPIRED';
export const LOGOUT: string = 'LOGOUT';
export const UPDATE_SETTINGS: string = 'UPDATE_SETTINGS';
export const UPDATE_LANGUAGE: string = 'UPDATE_LANGUAGE';
export const PROJECTS_RECEIVED: string = 'PROJECTS_RECEIVED';
export const PROJECTS_UPDATED: string = 'PROJECTS_UPDATED';
export const OBJECTS_RECEIVED: string = 'OBJECTS_RECEIVED';
export const STATIONS_RECEIVED: string = 'STATIONS_RECEIVED';
export const WHOAMI: string = 'WHOAMI';

export const ORIGINAL_SELECTED: string = 'ORIGINAL_SELECTED';
export const SET_REPORT_SESSION: string = 'SET_REPORT_SESSION';

export const PANO_FILE_URL_RECEIVED: string = 'PANO_FILE_URL_RECEIVED';
export const SET_SHOW_LOADING_SPINNER: string = 'SET_SHOW_LOADING_SPINNER';
