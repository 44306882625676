import { post, get, tokenizedGet } from '../API';
import { PATH_PING, PATH_AUTH, PATH_WHOAMI, PATH_AUTH_DIRECTLINK } from '../paths';

export function getPing() {
	return get(PATH_PING);
}

export function postLogin(mail, password) {
	return post(PATH_AUTH, {
		mail: mail,
		password: password,
	});
}

export function getWhoAmI(token) {
	return tokenizedGet(PATH_WHOAMI, token);
}

export function loginDirectLink(directLink) {
	return get(PATH_AUTH_DIRECTLINK.replace(":link", directLink), {
		directLink
	});
}
