// @flow
import * as Cookie from '../misc/helper/cookieHelper';
import i18n from '../i18n/i18n';

import { DISPATCH_MESSAGE, LOGIN_SUCCESSFUL, LOGIN_FAILED, LOGIN_RESET, UPDATE_LANGUAGE, TOKEN_EXPIRED, WHOAMI, LOGOUT, SET_IS_DIRECTLINK } from '../actions/utils/actionTypes';

import { TOASTIFY_SEVERITY } from '../misc/const';
import * as Request from '../backend/requests/loginRequests';
import { _requestProjects, _requestSettings, _requestProject } from './dataActions';

import { Dispatch, ThunkAction, Action, MessageType } from '../misc/flowTypes';
import { FORBIDDEN, UNAUTHORIZED, BAD_REQUEST } from '../misc/httpCodes';
import { makeActionCreator } from './utils/actionCreator';

/**
 * Redux Action, wenn die Anmeldung erfolgreich war
 * @param  {String} token Der Token
 * @return {Object}       Die Redux Action
 */
export const _loginSuccessful = makeActionCreator(LOGIN_SUCCESSFUL, 'token');

/**
 * Redux Action wenn die Anmeldung fehlschlaegt
 * @return {Object} Die Redux Action
 */
export const _loginFailed = makeActionCreator(LOGIN_FAILED);

/**
 * Redux Action fuer das zuruecksetzen der Anmeldung
 * @return {Object} Die Redux Action
 */
export const _loginReset = makeActionCreator(LOGIN_RESET);

/**
 * Redux Action fuer die Abmeldung
 * @return {Object} Die Redux Action
 */
export const _logout = makeActionCreator(LOGOUT);

/**
 * Redux Action fuer die Daten des angeldeten Benutzers
 * @param {Object} user Der angeldete Benutzer
 * @return {Object} Die Redux Action
 */
export const _whoamiReceived = makeActionCreator(WHOAMI, 'user');

export const setLanguage = makeActionCreator(UPDATE_LANGUAGE, 'lang'); 

export const _isDirectLink = makeActionCreator(SET_IS_DIRECTLINK); 

/**
 * Behandelt bekannte Fehlermeldungen bei der Anmeldung und schickt entsprechende Nachrichten
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 */
function handleLoginError(dispatch: Dispatch, err: Object): void {
	if (err.hasOwnProperty('response')) {
		const { response } = err;
		if (response && response.hasOwnProperty('status'))
			switch (response.status) {
				case UNAUTHORIZED:
					dispatch(sendErrorMessage('messages.loginFailed'));
					break;
				case BAD_REQUEST:
					dispatch(sendErrorMessage('messages.fillAllFields'));
					break;
				case FORBIDDEN:
                    dispatch(sendErrorMessage('messages.userMissingRight'));
					break;
				default:
					dispatch(sendErrorMessage('messages.unknown'));
					break;
			}
	} else {
        dispatch(sendErrorMessage('messages.serverNotResponding'));
    }

	dispatch(_loginFailed());
}

function handleError(dispatch: Dispatch, err: Object): void {
	if (err.hasOwnProperty('response')) {
		const { response } = err;
		if (response && response.hasOwnProperty('status'))
			switch (response.status) {
				case UNAUTHORIZED:
					dispatch(sendErrorMessage('messages.accessForbidden'));
					break;
				case BAD_REQUEST:
					dispatch(sendErrorMessage('messages.dataNotFound'));
					break;
				case FORBIDDEN:
					dispatch(sendErrorMessage('messages.accessForbidden'));
					break;
				default:
					dispatch(sendErrorMessage('messages.unknown'));
					break;
			}
	}

	dispatch(sendErrorMessage('messages.serverNotResponding'));
}

export function changeLanguage(lang) {
	return (dispatch, getState) => {
		const {
			settings: { lang: current },
		} = getState();
		const storageLang = i18n.language;

		if (lang !== storageLang) {
			i18n.changeLanguage(lang);
		}
		if (lang !== current) {
			dispatch(setLanguage(lang));
		}
	};
}

/**
 * Erstellt eine Action, die die Anwendung initialisiert
 * @return {Function}     Init Action
 */
export function _init(): ThunkAction {
	return (dispatch: Dispatch) => {
		const lang = localStorage.getItem('i18nextLng');
		dispatch(changeLanguage(lang));

		const token: ?string = Cookie.get('viewertoken');
		dispatch(_loginSuccessful(token));

		Request.getPing()
			.then((result: Object) => {
				const previewproject: ?string = Cookie.get('previewproject');

				if (token) {
					dispatch(_requestWhoami(token));
					if (previewproject) dispatch(_requestSettings(token, () => dispatch(_requestProject(previewproject))));
					else dispatch(_requestSettings(token, () => dispatch(_requestProjects())));
				}
			})
			.catch((error: Object) => {
				handleError(dispatch, error);
			});
	};
}

/**
 * Redux Action fuer die Anmeldung
 * @param  {String} username Der Benutzername
 * @param  {String} password Das Passwort
 * @return {Function} Die Redux Action
 */
export function _login(username: string, password: string): ThunkAction {
	return (dispatch: Dispatch) => {
		Request.postLogin(username, password)
			.then((result: Object) => {
				const {
					data: { token },
				} = result;
				dispatch(_requestSettings(token, () => dispatch(_requestProjects())));
				Cookie.set('viewertoken', token);
				dispatch(_loginSuccessful(token));
				dispatch(sendSuccessMessage('messages.loginSuccessful'));
				dispatch(_requestWhoami(token));
			})
			.catch((err: Object) => {
				handleLoginError(dispatch, err);
			});
	};
}

export function _loginByDirectLink(directLink: string): ThunkAction {
	return (dispatch: Dispatch) => {
		return Request.loginDirectLink(directLink)
			.then((result: Object) => {
				const {
					data: { token },
				} = result;
				dispatch(_isDirectLink());				
				dispatch(_requestSettings(token, () => dispatch(_requestProjects())));
				Cookie.set('viewertoken', token);
				dispatch(_loginSuccessful(token));
				dispatch(sendSuccessMessage('messages.loginSuccessful'));
				dispatch(_requestWhoami(token));

				return token;
			})
			.catch((err: Object) => {
				handleLoginError(dispatch, err);
			});
	};
}

/**
 * Redux Action fuer die Anmeldung einer Vorschau
 * @param  {String} username Der Benutzername
 * @param  {String} password Das Passwort
 * @return {Function} Die Redux Action
 */
export function _loginPreview(data: Object): ThunkAction {
	const { projectId, token } = data;

	return (dispatch: Dispatch) => {
		dispatch(_logout());
		Cookie.set('viewertoken', token);
		Cookie.set('previewproject', projectId);
		dispatch(_loginSuccessful(token));
		dispatch(_requestSettings(token, () => dispatch(_requestProject(projectId))));
		dispatch(_requestWhoami(token));
	};
}

/**
 * Erfragt die Daten des angemeldeten Benutzers
 * @param {String} token Der API Token
 * @return {Function} Die Abfrage
 */
function _requestWhoami(token: string): ThunkAction {
	return (dispatch: Dispatch) => {
		Request.getWhoAmI(token)
			.then((result: Object) => {
				dispatch(_whoamiReceived(result.data.user));
			})
			.catch((error: Object) => {
				handleError(dispatch, error);
			});
	};
}

/**
 * Redux Action fuer den Fall das der Token abgelaufen ist
 * @param {Boolean} [message=true] Ob eine Nachricht angezeigt werden soll
 * @return {Object} Die Redux Action
 */
export function _tokenExpired(message: ?boolean = true): ThunkAction | Action {
	if (message) {
		return (dispatch: Dispatch) => {
			dispatch(sendErrorMessage('messages.tokenExpired'));
			dispatch(_tokenExpired(false));
		};
	} else {
		return {
			type: TOKEN_EXPIRED,
		};
	}
}

/**
 * Redux Action, die eine Nachricht ausloest
 * @param  {String} text     Text oder JSX der Nachricht
 * @param  {String} severity Schwere der Nachricht
 * @param  {String} position Position der Nachricht. Hilfe: react-toastify
 * @return {Object}          Die Redux Action
 */
export function _message({ text, severity, position }: MessageType): Action {
	return {
		type: DISPATCH_MESSAGE,
		text,
		severity,
		position,
	};
}

export function sendSuccessMessage(m) {
	return (dispatch) => {
		dispatch(
			_message({
				text: m,
				severity: TOASTIFY_SEVERITY.SUCCESS,
			})
		);
	};
}

export function sendErrorMessage(m) {
	let message = m;
	if (!m) message = 'messages.unknown';
	return (dispatch) => {
		dispatch(
			_message({
				text: message,
				severity: TOASTIFY_SEVERITY.ERROR,
			})
		);
	};
}
