// @flow
//state objects
import { PANO_UNITSYSTEMS, PROJECT_LEVEL_VIEWS } from '../misc/const';
//actions
import { LOGIN_SUCCESSFUL, LOGIN_FAILED, LOGIN_RESET, TOKEN_EXPIRED, LOGOUT, UPDATE_SETTINGS, UPDATE_LANGUAGE, WHOAMI, SET_IS_DIRECTLINK } from '../actions/utils/actionTypes';
import { Action } from '../misc/flowTypes';
import * as Cookie from '../misc/helper/cookieHelper';

import cloneDeep from 'lodash.clonedeep';

const initialState = {
	settings: {
		objectlist: {},
		lang: 'en',
		layout: 'threeToNine',
		station: {},
        tileserver: 'osm.k8s-dev.ibak-kiel.de',
		panoramo: {
			general: {
				displayCrosshair: false,
				units: PANO_UNITSYSTEMS.METRIC,
				tooltip: true,
				zoom: 90,
			},
			keyboard: {
				keybspeed: 10,
				keybaccelerate: 0.2,
				keybfriction: 0.45,
			},
			pointCloud: {
				background: '#0089AF',
				cloud: '#FFFF00',
				highlight: '#50f442',
				measurement: '#FFFF00',
				contour: '#FF0000',
			},
			object: {
				highlight: '#50f442',
				border: '#000000',
			},
		},
	},
	authentication: {
		token: '',
		name: '',
		isDirectLink: false,
	},
	resetLogin: false,
};

/**
 * Setzt resetLogin auf true in dem Redux Store
 * @param  {Object} state Der aktuelle Status im Redux Store
 * @return {Object}       Der neue Status
 */
function loginFailed(state: Object): Object {
	return Object.assign({}, state, { resetLogin: true });
}

/**
 * Setzt resetLogin auf false in dem Redux Store
 * @param {Object} state Der aktuelle Status im Redux Store
 * @return {Object}      Der neue Status
 */
function loginReset(state: Object): Object {
	return Object.assign({}, state, { resetLogin: false });
}

/**
 * Entfernt den Benuter und den Token aus der Authentifizierung im dem Redux Store
 * @param  {Object} state Der aktuelle Status im Redux Store
 * @return {Object}       Der neue Status
 */
function tokenExpired(state: Object): Object {
	Cookie.remove('viewertoken');
	Cookie.remove('previewproject');

	return Object.assign({}, state, {
		authentication: {
			token: '',
		},
	});
}

/**
 * Fuehrt eine Abmeldung aus der Anwendung durch
 * @param {Object} state Der aktuelle Status im Redux Store
 * @return {Object} Der neue Status
 */
function logout(state: Object): Object {	
	return { 
		...tokenExpired(state), 
		...initialState, 
		projects: null,
		currentProject: null,
		currentStationView: null,
		mainViewTab: PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW,
	};
}

/**
 * Aktualisiert die Einstellungen im State
 * @param {Object} state Der aktuell Status im Redux Store
 * @param {Object} settings Die neuen Settings
 * @return {Object} Der neue Status
 */
function updateSettings(state: Object, settings: Object): Object {
	let current: Object = state.settings;
	if (current.lang !== settings.lang) {
		current = Object.assign({}, current, settings);
		state.settings = current;

		return cloneDeep(state);
	}
	current = Object.assign({}, current, settings);
	state.settings = current;

	return cloneDeep(state);
}

/**
 * Setzt den angemeldeten Usernamen im State
 * @param  {Object} state Der aktuelle Status im Redux Store
 * @return {Object}       Der angemeldete User
 */
function userName(state: Object, user: Object): Object {
	let name: string = '';

	if (user.hasOwnProperty('forname')) {
		name = user.forname;
	}
	if (user.hasOwnProperty('surname')) {
		if (name !== '') {
			name = `${name} ${user.surname}`;
		} else {
			name = user.surname;
		}
	}

	let current: Object = state.authentication;
	current = Object.assign({}, current, { name: name });
	state.authentication = current;

	return cloneDeep(state);
}

/**
 * Funktion, die vom Redux aufgerufen wird, wenn actions dispatched werden
 * @param  {object} [state=initialState] Aktueller Status
 * @param  {object} action                Inhalt der Action mit den Werten
 * @return {object}                       Neuer Status mit dem alten gemerged
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: Object = initialState, action: Action): Object {
	switch (action.type) {
		case LOGIN_SUCCESSFUL:
			return {
				...state,
				authentication: {
					...state.authentication,
					token: action.token,
				},
			};
		case LOGIN_FAILED:
			return loginFailed(state);
		case LOGIN_RESET:
			return loginReset(state);
		case SET_IS_DIRECTLINK:
			return {
				...state,
				authentication: {
					...state.authentication,
					isDirectLink: true,
				},
			}
		case TOKEN_EXPIRED:
			return logout(state);
		case LOGOUT:
			return logout(state);
		case UPDATE_SETTINGS:
			return updateSettings(state, action.settings);
		case UPDATE_LANGUAGE:
			return {
				...state,
				settings: {
					...state.settings,
					lang: action.lang,
				},
			};
		case WHOAMI:
			return userName(state, action.user);
		default:
			return state;
	}
}
